import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "./utils/http";
import "./global";
import "./permission";
import api from "./utils/api";
import LoadMore from "./components/LoadMore.vue";
import Notifications from "./components/NotificationPlugin";

Vue.config.productionTip = false;
Vue.component("LoadMore", LoadMore); //全局自定义组件
Vue.prototype.$api = api;

Vue.use(Notifications);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
