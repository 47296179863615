import axios from "axios";
import Vue from "vue";
import store from "../store";
// 设置基础URL
axios.defaults.baseURL = "";
// 设置请求超时时间
// axios.defaults.timeout = 120000;

// 已经为ajax请求设置了loading 请求前自动调用 请求完成自动结束
// axios.interceptors.request.handlers = [];
// axios.interceptors.response.handlers = [];
// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    if (!config.params) {
      config.params = {};
    }
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    store.commit("SET_SHOW_LOADING", false);
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 可以在这里对返回的数据进行错误处理 如果返回的 code 不对 直接报错
    // 就可以省去在业务代码里重复判断
    // 例子
    // if (res.code != 0) {
    //     this.$Message.error(res.msg)
    //     return Promise.reject()
    // }
    // console.log(response);
    // console.log(response.data);
    if (response.data) {
      response = response.data;
    }
    return response;
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;
