import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    mini: false,
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .6)",
    barImage: `${process.env.BASE_URL}image/sidebar-3.jpg`,
    menuItems: [
      {
        name: "VisitingResearch", // 要跳转的路由名称 不是路径
        icon: "mdi-home", // icon类型
        text: "主页", // 文本内容
      },
      {
        text: "二级菜单",
        icon: "mdi-menu-open",
        children: [
          {
            icon: "mdi-table",
            name: "Table",
            text: "表格",
          },
          {
            text: "三级菜单",
            icon: "mdi-menu-open",
            children: [
              {
                icon: "mdi-message",
                name: "Msg",
                text: "查看消息",
              },
              {
                icon: "mdi-account-lock",
                name: "password",
                text: "修改密码",
              },
              {
                icon: "mdi-account",
                name: "userinfo",
                text: "基本资料",
              },
            ],
          },
        ],
      },
    ],
    openId: process.env.NODE_ENV !== "development" ? undefined : "123",
    currentUser:
      process.env.NODE_ENV !== "development"
        ? undefined
        : {
            ID: "2055",
            IDCARD: "412727199012012322",
            PNAME: "姓名",
            PSEX: "男",
            PAGE: "17",
            PHONE: "18015938665",
            PADDRESS: "",
            WECHAT_OPENID: "123",
          },
  },
  mutations: {
    SET_MINI(state, param) {
      state.mini = param;
    },
    SET_MINIAUTO(state) {
      state.mini = !state.mini;
    },
    SET_OPENID(state, param) {
      state.openId = param;
    },
    SET_CURRENTUSER(state, param) {
      state.currentUser = param;
    },
  },
});

export default store;
