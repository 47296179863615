/**************************************时间格式化处理************************************/
global.dateFtt = function(fmt, date) {
  if (!(date instanceof Date)) {
    console.error("时间格式错误," + date);
    return date;
  }
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

global.blobToDataURL = (blob, f) => {
  let reader = new FileReader();
  reader.onload = function(evt) {
    let base64 = evt.target.result;
    f(base64);
  };
  reader.readAsDataURL(blob);
};

global.questions = {
  eatingHabits: {
    饮食行为量表: [
      {
        question: "1．我听从医务人员的饮食建议",
        selection: "frequency_1",
      },
      {
        question: "2．我难以限制啤酒/葡萄酒摄入",
        selection: "frequency_1",
      },
      {
        question: "3．很多时候我不能限制禁食的食物",
        selection: "frequency_1",
      },
      {
        question: "4．我对需要限食禁食的食物表示犹豫",
        selection: "frequency_1",
      },
      {
        question: "5．中断食谱对我的健康没有影响",
        selection: "frequency_1",
      },
      {
        question: "6．不高兴时，我不关心摄入食物的种类",
        selection: "frequency_1",
      },
      {
        question: "7．我今天和往常一样摄人同样多的液体",
        selection: "frequency_1",
      },
      {
        question: "8．我的家人帮肋我遵从食谱",
        selection: "frequency_1",
      },
      {
        question: "9．在饭店吃饭时，我不能遵从食谱",
        selection: "frequency_1",
      },
      {
        question: "10．不高兴时，我的饮水量超过了推荐范围",
        selection: "frequency_1",
      },
      {
        question: "11．我避免含盐多的食物",
        selection: "frequency_1",
      },
      {
        question: "12．我严格保持每天饮水量在推荐范围内",
        selection: "frequency_1",
      },
      {
        question: "13．我每天至少喝5-6大杯水",
        selection: "frequency_1",
      },
      {
        question: "14．我有时关注那些我不能摄入的食物",
        selection: "frequency_1",
      },
      {
        question: "15．我按照医嘱服药",
        selection: "frequency_1",
      },
      {
        question: "16．饭前我会仔细称量摄入的食物",
        selection: "frequency_1",
      },
      {
        question: "17．夏天我难以限制液体摄入",
        selection: "frequency_1",
      },
      {
        question: "18．我定期自秤体重",
        selection: "frequency_1",
      },
      {
        question: "19．我避免饮水超过推荐的范围",
        selection: "frequency_1",
      },
      {
        question: "20．我决定（摄入）食物（即使违背医生建议）",
        selection: "frequency_1",
      },
      {
        question: "21．我进餐时会摄入含盐食物",
        selection: "frequency_1",
      },
      {
        question: "22．我完全限制含钾食物的摄入",
        selection: "frequency_1",
      },
      {
        question: "23．我完全限制盐的摄入",
        selection: "frequency_1",
      },
      {
        question: "24．我完全服用药物 ",
        selection: "frequency_1",
      },
      {
        question: "25．我完全坚持推荐的摄液量",
        selection: "frequency_1",
      },
    ],
  },
  qalityOfLife: {
    躯体生理功能: [
      {
        question: "1、您有足够的精力应付日常生活?",
        selection: "frequency_2",
      },
      {
        question: "2、您的日常活动(如洗澡，穿衣，上厕所)是否受到限制?",
        selection: "limit_1",
      },
      {
        question: "3、您的日常购物、买菜等活动是否受限?",
        selection: "limit_1",
      },
      {
        question: "4、您起床后是否感到精力充沛?",
        selection: "frequency_2",
      },
      {
        question: "5、您需要进入睡眠状态的时间?",
        selection: "time_1",
      },
    ],
    疾病因素: [
      {
        question: "6、您是否会皮肤干燥瘙痒",
        selection: "serious_1",
      },
      {
        question: "7、您是否会手足麻木",
        selection: "serious_1",
      },
      {
        question: "8、您是否会胸闷",
        selection: "serious_1",
      },
      {
        question: "9、您是否会水肿",
        selection: "serious_1",
      },
      {
        question: "10、您是否会腰部酸痛",
        selection: "serious_1",
      },
      {
        question: "11、您是否会恶心、呕吐等胃肠道不适的症状",
        selection: "serious_1",
      },
      {
        question: "12、您是否会腿部不自主活动?",
        selection: "serious_1",
      },
      {
        question: "13、您是否会因为很多食物的摄入受到限制而感到难受?",
        selection: "unbearable_1",
      },
      {
        question: "14、您因为需要控制饮水而感到难受?",
        selection: "unbearable_1",
      },
      {
        question: "15、您担心因为疾病您的外貌受到影响吗?",
        selection: "influence_1",
      },
      {
        question: "16、您是否因为该病而不能集中精力学习或工作?",
        selection: "serious_1",
      },
      {
        question: "17、您因为腹膜透析导管外露而受到烦恼吗?",
        selection: "trouble_1",
      },
      {
        question:
          "18、您因为需要每天进行腹膜透析治疗或到定期门诊随访感到烦恼吗?",
        selection: "trouble_1",
      },
      {
        question: "19、腹膜透析影响您的外出旅行吗?",
        selection: "influence_1",
      },
      {
        question: "20、腹膜透析治疗是否给您的家庭居住环境带来不便?",
        selection: "inconvenient",
      },
    ],
    心理状态: [
      {
        question: "21、您为您的健康状况而感到不安吗?",
        selection: "frequency_3",
      },
      {
        question: "22、您担心未来疾病的恶化吗?",
        selection: "frequency_3",
      },
      {
        question: "23、您因为疾病而心情烦闷吗?",
        selection: "frequency_3",
      },
      {
        question: "24、您易与他人发生冲突吗?",
        selection: "frequency_3",
      },
      {
        question: "25、您是否因为疾病而感到不公吗?",
        selection: "frequency_3",
      },
      {
        question: "26、您因为疾病是否曾想结束自己的生命",
        selection: "frequency_3",
      },
      {
        question: "27、您会情绪低落吗?",
        selection: "frequency_3",
      },
      {
        question: "28、您感到自己是家人的负担吗?",
        selection: "frequency_3",
      },
      {
        question: "29、您担心治疗费用无法承担吗?",
        selection: "worry_1",
      },
      {
        question: "30、您担心让他人知道您的疾病吗(如朋友、单位同事等)?",
        selection: "worry_1",
      },
    ],
    社会角色和社会交往: [
      {
        question: "31、您现在的工作或活动时间是否受到限制?",
        selection: "limit_1",
      },
      {
        question: "32、您目前对工作种类的选择是否受到限制?",
        selection: "limit_1",
      },
      {
        question: "33、您目前对部分活动的选择是否有所顾虑?",
        selection: "concern_1",
      },
      {
        question: "34、因为身体原因，您的工作能力会受到质疑吗?",
        selection: "question_1",
      },
      {
        question: "35、您认为您的社交是否受影响?",
        selection: "influence_1",
      },
      {
        question: "36、您的家庭生活是否受到影响?",
        selection: "influence_1",
      },
    ],
    生活满意度: [
      {
        question: "37、您对目前的生活质量满意吗?",
        selection: "satisfy_1",
      },
      {
        question: "38、您对目前的健康状况是否满意?",
        selection: "satisfy_1",
      },
      {
        question: "39、您对自己日常做事能力是否满意?",
        selection: "satisfy_1",
      },
      {
        question: "40、您对自己的睡眠情况满意吗?",
        selection: "satisfy_1",
      },
      {
        question: "41、您对您的性生活感到困扰吗?",
        selection: "trouble_2",
      },
    ],
  },
  selfManagement: {
    知识方面: [
      {
        question: "1.	腹透对心血管系统的稳定性好，易保持血压稳定。",
        selection: "correct_1",
      },
      {
        question: "2.	如果透析液混浊，有可能是发生了腹膜炎",
        selection: "correct_1",
      },
      {
        question: "3.	腹膜透析患者不可以适当吃蛋白质含量较高的食物。",
        selection: "correct_1",
      },
      {
        question: "4.	钙片应餐中嚼服。",
        selection: "correct_1",
      },
      {
        question: "5.	管道移位不会引起超滤突然减少。",
        selection: "correct_1",
      },
      {
        question: "6.	桔子、香蕉等含钾高的食物，应根据检查结果吃。",
        selection: "correct_1",
      },
      {
        question: "7.	豆制品因含磷较高，应根据检查结果吃",
        selection: "correct_1",
      },
      {
        question: "8.	洗手完毕后可易值接用洗净的手关水龙头。",
        selection: "correct_1",
      },
      {
        question: "9.	操作时短管深蓝色顶端可以用手碰触。",
        selection: "correct_1",
      },
      {
        question: "10.	操作前应该用流动水洗手。",
        selection: "correct_1",
      },
    ],
    态度方面: [
      {
        question: "1.	您认为在家腹透时安排相对独立的房间重要性如何",
        selection: "important_1",
      },
      {
        question: "2.	您认为在家腹透时戴口罩的重要性如何?",
        selection: "important_1",
      },
      {
        question: "3.	您认为在家腹透前规范洗手的重要性如何？",
        selection: "important_1",
      },
      {
        question: "4.	您认为关注自己每天的尿量的重要性如何？",
        selection: "important_1",
      },
      {
        question: "5.	您认为每次称量超滤液的重要性如何",
        selection: "important_1",
      },
      {
        question: "6.	您认为每天计划早、中、晚餐分别吃什么的重要性如何?",
        selection: "important_1",
      },
      {
        question: "7.	您认为洗澡时保护出口处的重要性如何?",
        selection: "important_1",
      },
      {
        question: "8.	您认为在家腹透时每天规律定时运动的重要性如何?",
        selection: "important_1",
      },
      {
        question: "9.	您认为外接短管是否固定的重要性如何？",
        selection: "important_1",
      },
      {
        question: "10. 您认为外接短管是否固定的重要性如何？",
        selection: "important_1",
      },
      {
        question: "11. 您认为按时规律服药的重要性如何?",
        selection: "important_1",
      },
    ],
    行为方面: [
      {
        question: "1.	戴口罩应遮住口、鼻",
        selection: "correct_1",
      },
      {
        question: "2.	透析前必须检查透析液的渗漏情况。",
        selection: "correct_1",
      },
      {
        question: "3.	每天必须测量并记录血压。",
        selection: "correct_1",
      },
      {
        question: "4.	体位不当时透析液有可能灌不进去",
        selection: "correct_1",
      },
      {
        question: "5.	您必须每天记录超滤液的量",
        selection: "correct_1",
      },
      {
        question: "6.	透析过程中，若体重、血压突然增高，应立即就诊",
        selection: "correct_1",
      },
      {
        question: "7.	每天进行30分钟左右的慢走、上下楼梯或打太极等有氧运动",
        selection: "correct_1",
      },
    ],
    行为依从性方面: [
      {
        question: "1.	操作过程中手如果接触其他物品会再次洗手",
        selection: "frequency_4",
      },
      {
        question: "2.	每次记录透析液的性质(颜色、混浊度、量)。",
        selection: "frequency_4",
      },
      {
        question: "3.	换液后每次更换新的碘伏帽",
        selection: "frequency_4",
      },
      {
        question: "4.	每天测量血压",
        selection: "frequency_4",
      },
      {
        question: "5.	每天称体重。",
        selection: "frequency_4",
      },
      {
        question: "6.	按时、规律服药。",
        selection: "frequency_4",
      },
      {
        question: "7.	自觉、定期门诊随访",
        selection: "frequency_4",
      },
    ],
  },
};

global.selection = {
  concern_1: ["没有顾虑", "有一点顾虑", "中等顾虑", "顾虑较大", "顾虑很大"],
  correct_1: ["完全正确", "基本正确", "不确定", "基本不正确", "完全不正确"],
  frequency_1: ["从来不这样", "很少这样", "有时这样", "常常这样", "总是这样"],
  frequency_2: ["总是", "经常", "有时", "很少", "从不"],
  frequency_3: ["从不", "很少", "有时", "经常", "总是"],
  frequency_4: ["从不", "很少", "有时", "偶尔", "总是"],
  important_1: ["非常重要", "比较重要", "不确定", "比较不重要", "根本不重要"],
  inconvenient: ["没有不便", "有一点不便", "中等不便", "较大不便", "很大不便"],
  influence_1: ["没有影响", "有一点影响", "中等影响", "影响较大", "影响很大"],
  limit_1: ["毫无限制", "有些限制", "限制一般", "非常限制", "无法做"],
  question_1: [
    "没有受到质疑或没有工作",
    "受到一点质疑",
    "受到中等质疑",
    "受到较大质疑",
    "受到很大质疑",
  ],
  satisfy_1: ["很满意", "较满意", "一般满意", "较不满意", "很不满意"],
  serious_1: ["没有", "有一点", "中等", "严重", "很严重"],
  time_1: ["10分钟", "10．20分钟", "20．30分钟", "30分钟以上", "必须服用药物"],
  trouble_1: ["没有烦恼", "有一点烦恼", "中等烦恼", "烦恼较大", "烦恼很大"],
  trouble_2: ["毫无困扰", "一点困扰", "中等困扰", "较困扰", "很困扰"],
  unbearable_1: ["不难受", "有一点难受", "中等难受", "难受", "很难受"],
  worry_1: ["不担心", "一点担心", "中等担心", "较担心", "很担心"],
};
