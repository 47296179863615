import router from "./router";
import store from "./store";
import axios from "axios";

const isPhone = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);

router.beforeEach((to, from, next) => {
  if (isPhone) {
    if (store.state.openId || !location.search) {
      next();
    } else {
      let url = location.search; //获取url中"?"符后的字符串
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      axios
        .post("/api/WXGZH/WX_GetOpenid/1", {
          CODE: theRequest["code"],
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            console.warn("获取微信openid异常", data);
            store.commit("SET_OPENID", "no_open_id");
            return;
          } else {
            store.commit("SET_OPENID", data.openid);
            axios
              .post("/api/SNepUser/Get_NepUser/1", {
                WECHAT_OPENID: data.openid,
              })
              .then((res) => {
                let data = JSON.parse(res);
                if (data.code !== 0) {
                  console.warn("获取人员信息异常", data);
                  store.commit("SET_CURRENTUSER", undefined);
                  return;
                } else {
                  let temp = undefined;
                  if (data.data) {
                    let temp_1 = JSON.parse(data.data || "[]");
                    if (temp_1.length > 0) {
                      temp = temp_1[0];
                    }
                  }
                  store.commit("SET_CURRENTUSER", temp);
                }
              })
              .catch((error) => {
                console.error("获取人员信息报错", error);
              })
              .finally(() => {
                console.log(theRequest);
                if (theRequest["state"] === "1") {
                  next("/Questions?tab=1");
                } else if (theRequest["state"] === "2") {
                  next("/DoctorCare");
                } else {
                  next();
                }
              });
          }
        })
        .catch((error) => {
          next();
          console.error("获取微信openid报错", error);
        });
    }
  } else if (to.path === "/Login") {
    next();
    // } else if (!localStorage.getItem("account")) {
    //   next("/Login");
  } else {
    next();
  }
});
router.afterEach(() => {});
