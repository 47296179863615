import axios from "axios";

const httpApi = {
  // get(config = {}) {
  //   return axios.get("/api/...", config);
  // },
  //  查询团检人员接口
  //  COMPANYNAME 公司名称 or
  //  CBATCHNUMBER 批次 or
  //  CIDENTITY 身份证 or
  //  CNAME  姓名 or
  //  CTELEPHONE 电话 or
  //  page_number
  //  page_size
  postGet_GroupUser(data = {}, config = {}) {
    return axios.post("api/Group/get_GroupUser/1", data, config);
  },
  postGet_Dialysisp(data = {}, config = {}) {
    return axios.post("api/SDialysisp/get_Dialysisp/1", data, config);
  },
  postGet_SAutop(data = {}, config = {}) {
    return axios.post("api/SAutop/Get_SAutop/1", data, config);
  },
  postT_SAutop(data = {}, config = {}) {
    return axios.post("api/SAutop/T_SAutop/1", data, config);
  },
  postAdd_CAPD(data = {}, config = {}) {
    return axios.post("api/SUCAPD/add_CAPD/1", data, config);
  },
  postAdd_SNepgauge(data = {}, config = {}) {
    return axios.post("api/SNepgauge/add_SNepgauge/1", data, config);
  },
  postAdd_NepUser(data = {}, config = {}) {
    return axios.post("api/SNepUser/add_NepUser/1", data, config);
  },
  postU_NepUser(data = {}, config = {}) {
    return axios.post("api/SNepUser/U_NepUser/1", data, config);
  },
  postAdd_Testsheet(data = {}, config = {}) {
    return axios.post("api/STestsheet/add_Testsheet/1", data, config);
  },
  postGet_SCare(data = {}, config = {}) {
    return axios.post("api/SCare/Get_SCare/1", data, config);
  },
  postGet_Squantiey(data = {}, config = {}) {
    return axios.post("api/Sflowup/Get_Squantiey/1", data, config);
  },
  postAdd_SMFT_New(data = {}, config = {}) {
    return axios.post("api/SMFT/add_SMFT_New/1", data, config);
  },
  postGet_SMFT(data = {}, config = {}) {
    return axios.post("api/SMFT/Get_SMFT/1", data, config);
  },
  post(data = {}, config = {}) {
    return axios.post("api/...", data, config);
  },
};

export default httpApi;

// axios.request（config）
// axios.get（url [，config]）
// axios.delete（url [，config]）
// axios.head（url [，config]）
// axios.post（url [，data [，config]]）
// axios.put（url [，data [，config]]）
// axios.patch（url [，data [，config]]）
