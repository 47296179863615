import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const wechatRoute = [
  {
    path: "/WechatHome",
    name: "WechatHome",
    component: () => import("../view/Wechat/WechatHome.vue"),
    children: [
      {
        path: "/UserInfo",
        name: "UserInfo",
        component: () => import("../view/Wechat/UserInfo.vue"),
      },
      {
        path: "/EatingHabits",
        name: "EatingHabits",
        component: () => import("../view/Wechat/EatingHabits.vue"),
      },
      {
        path: "/QualityOfLife",
        name: "QualityOfLife",
        component: () => import("../view/Wechat/QualityOfLife.vue"),
      },
      {
        path: "/SelfManagement",
        name: "SelfManagement",
        component: () => import("../view/Wechat/SelfManagement.vue"),
      },
      {
        path: "/CAPDAPD",
        name: "CAPDAPD",
        component: () => import("../view/Wechat/CAPDAPD.vue"),
      },
      {
        path: "/Questions",
        name: "Questions",
        component: () => import("../view/Wechat/Questions.vue"),
      },
      {
        path: "/UploadReport",
        name: "UploadReport",
        component: () => import("../view/Wechat/UploadReport.vue"),
      },
      {
        path: "/DoctorCare",
        name: "DoctorCare",
        component: () => import("../view/Wechat/DoctorCare.vue"),
      },
      {
        path: "/History",
        name: "History",
        component: () => import("../view/Wechat/History.vue"),
      },
      {
        path: "/HistoryJieRui",
        name: "HistoryJieRui",
        component: () => import("../view/Wechat/HistoryJieRui.vue"),
      },
    ],
  },
];

const routes1 = [
  {
    path: "/Home",
    component: () => import("../components/Home.vue"),
    children: [
      {
        path: "/VisitingResearch",
        name: "VisitingResearch",
        meta: {
          text: "访视研究",
        },
        component: () =>
          import("../view/VisitingResearch/VisitingResearch.vue"),
      },
      {
        path: "/Table",
        name: "Table",
        meta: {
          text: "表格",
        },
        component: () => import("../view/Table/Table.vue"),
      },
      {
        path: "/Msg",
        name: "Msg",
        meta: {
          text: "信息",
        },
        component: () => import("../view/Msg.vue"),
      },
    ],
  },
  ...wechatRoute,
  {
    path: "/Login",
    name: "Login",
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/Error",
    name: "Error",
    component: () => import("../components/Error.vue"),
  },
  { path: "*", redirect: "/Error" },
];

const commonRoutes = [...routes1, { path: "/", redirect: "/CAPDAPD" }];

const createRouter = () =>
  new VueRouter({
    mode: "hash",
    routes: commonRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
