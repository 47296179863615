<template>
  <div class="notifications">
    <transition-group name="list">
      <notification
        v-for="notification in notifications"
        :key="notification.timestamp.getTime()"
        :message="notification.message"
        :icon="notification.icon"
        :type="notification.type"
        :timestamp="notification.timestamp"
        :timeout="notification.timeout"
        :vertical-align="notification.verticalAlign"
        :horizontal-align="notification.horizontalAlign"
        :heightCallback="
          (val) => {
            heightCallback(val, notification);
          }
        "
        @on-close="removeNotification"
      >
      </notification>
    </transition-group>
  </div>
</template>
<script>
import Notification from "./Notification.vue";
export default {
  components: {
    Notification,
  },
  data() {
    return {
      notifications: this.$notifications.state,
    };
  },
  methods: {
    removeNotification(timestamp) {
      this.$notifications.removeNotification(timestamp);
    },
    heightCallback(e, notification) {
      notification.elmHeight = e;
    },
  },
};
</script>
<style lang="scss">
.list-move {
  transition: transform 0.3s, opacity 0.4s;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: opacity 0.4s;
}
.list-enter, .list-leave-to  /* .list-leave-active for <2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
$font-weight-bold: 500 !default;
$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$gray-color: #999999 !default;
$brand-primary: darken(#428bca, 6.5%) !default; // #337ab7
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-danger: #f44336 !default;
$brand-warning: #ff9800 !default;
$brand-info: #00bcd4 !default;
$brand-rose: #e91e63 !default;
$mdb-text-color-light: #ffffff !default;
$mdb-text-color-primary: unquote("rgba(0,0,0, 0.87)") !default;
$white-color: #ffffff !default;

$margin-base: 15px !default;
$border-radius-base: 4px !default;
/* SHADOWS */
$mdb-shadow-key-umbra-opacity: 0.2 !default;
$mdb-shadow-key-penumbra-opacity: 0.14 !default;
$mdb-shadow-ambient-shadow-opacity: 0.12 !default;

@mixin alert-color($color) {
  background-color: $color;
  color: $white-color;
  border-radius: $border-radius-base;
  @include shadow-big-color($color);
}

@mixin shadow-big-color($color) {
  box-shadow: 0 12px 20px -10px rgba($color, $mdb-shadow-key-penumbra-opacity *
          2),
    0 4px 20px 0px rgba(0, 0, 0, $mdb-shadow-ambient-shadow-opacity),
    0 7px 8px -5px rgba($color, $mdb-shadow-key-umbra-opacity);
}
.notifications {
  z-index: 999;
}
.alert {
  border: 0;
  border-radius: 0;
  position: relative;
  padding: 20px 15px;
  line-height: 20px;
  margin-bottom: 20px;
  max-width: 500px;

  .notifications & {
    margin-bottom: 0;
  }

  b {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: $font-size-small;
  }

  @include alert-color($gray-color);

  &.alert-info {
    @include alert-color(lighten($brand-info, 5%));
  }

  &.alert-danger {
    @include alert-color(lighten($brand-danger, 5%));
  }

  &.alert-primary {
    @include alert-color(lighten($brand-primary, 5%));
  }

  &.alert-warning {
    @include alert-color(lighten($brand-warning, 5%));
  }

  &.alert-success {
    @include alert-color(lighten($brand-success, 5%));
  }

  &-info,
  &-danger,
  &-warning,
  &-success {
    color: $mdb-text-color-light;
  }

  &-default {
    a,
    .alert-link {
      color: $mdb-text-color-primary;
    }
  }

  .close {
    float: right;
    font-size: inherit;
    font-weight: 700;
    line-height: 1;
    color: $white-color;
    opacity: 0.9;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  i[data-notify="icon"] {
    font-size: 30px;
    display: block;
    left: 15px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
  }

  span {
    display: block;
    min-width: 200px;
    max-width: 89%;
  }

  .alert-icon {
    display: block;
    float: left;
    margin-right: $margin-base;

    i {
      margin-top: -7px;
      top: 5px;
      position: relative;
    }
  }
}

.alert.alert-with-icon {
  padding-left: 65px;
}
</style>
