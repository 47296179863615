<template>
  <v-app class="m-app" style="background: #EEEEEE;">
    <notifications></notifications>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
.m-app {
  height: 100vh;
}
html,
body {
  height: 100%;
  background-color: #eeeeee !important;
  overflow-y: hidden !important;
}
.v-application ul {
  padding-left: 0px !important;
}
.v-dialog {
  background: white;
}
.v-dialog::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 12px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}

.v-dialog::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  border: 3px solid rgba(0, 0, 0, 0);
  box-shadow: 6px 0 0 #a5adb7 inset;
}

.v-dialog::-webkit-scrollbar-thumb:hover {
  box-shadow: 6px 0 0 #4a4a4a inset;
}
</style>
